/* Paste here all the project fonts as well as in /pages/_document.tsx file for faster downloading of all the fonts*/

@font-face {
  font-family: 'TeX Gyre Adventor';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/TexGyreAdventor/TexGyreAdventor-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Junicode';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Junicode/Junicode-BoldCondensed.woff2') format('woff2');
}

@font-face {
  font-family: 'Basteleur';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Basteleur/Basteleur-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Royals';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Royals/Royals-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'LeagueGothic';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/LeagueGothic/LeagueGothic-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'AmazonEmber';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/AmazonEmber/AmazonEmber_Lt.woff2') format('woff2');
}

@font-face {
  font-family: 'AmazonEmber';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/AmazonEmber/AmazonEmber_Rg.woff2') format('woff2');
}

@font-face {
  font-family: 'AmazonEmber';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/AmazonEmber/AmazonEmber_RgIt.woff2') format('woff2');
}

@font-face {
  font-family: 'AmazonEmber';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/AmazonEmber/Amazon-Ember-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'AmazonEmber';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/AmazonEmber/Amazon-Ember-MediumItalic.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Soap';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Soap/Soap.woff2') format('woff2');
}

@font-face {
  font-family: 'Bookerly';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Bookerly/Bookerly.woff2') format('woff2');
}
